.page-info {
  display: none; // flex
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba($secondary-color, 0.9);
  z-index: 1000;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 50px;
  box-sizing: border-box;
  overflow-y: scroll;
}

.info-buttons {
  width: 100%;
}

.info-content-outer {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.info-content {
  color: white;
  text-align: center;
  width: 60%;
  max-width: 600px;
}

.info-content p {
  font-size: 16px;
  padding-bottom: 20px;
}

.button-info-close {
  margin-top: 30px;
}
.page-error {
  position: fixed;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  background: $secondary-color;
  z-index: 200;
  color: white;
  display: none;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 30px;
}

.error-container {
  text-align: center;
  max-width: 570px;
}

.error-container p {
  font-size: 16px;
  margin-bottom: 20px;
}

.error-container img {
  width: 160px;
  margin-bottom: 50px;
}

.error-title {
  font-weight: bold;
  font-size: 30px;
  padding-bottom: 20px;
}
@import 'base';
@import 'start-page';
@import 'tutorial-page';
@import 'main-page';
@import 'info-page';
@import 'finish-page';
@import 'error-page';

.dot-bg {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
}

.start-bg {
  background-image: url('../assets/dot-bg.svg');
  background-repeat: repeat;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 30%;
  z-index: 0;
}

.start-bg-fade {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 30%;
  z-index: 1;
  background: linear-gradient(rgba(0,0,0,0), $secondary-color)
}

.is-mobile {
  min-width: 0;
  min-height: 0;
}